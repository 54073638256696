@import '../../App.scss';

.cf-wrapper{
    width: 100%;
    padding: 20px;

    .text-group-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;

        .smaller-text-group{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

        }

        .text-group{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }
    }

    .post-submit-message{
        border: 2px;
        padding-top: 20px;
        width: 100%;
        font-weight: 400;
        font-size: var(--mobile-m);
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        p{max-width: 235px;}
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
        .valid-message{

            color: #27792F;
        }
        .error-message{
            color: var(--red);
        }
        .hide{display: none;}

    }
}


.textField{
    height: fit-content;
    width: 100%;
    min-width: 230px;
    border-radius: 15px;
    position: relative;
    backdrop-filter: blur(4px);
    
    input{
        z-index: 1;
        height: 100%;
        width: 100%;
        background-color: var(--inv10);
        border-radius: 15px;

        padding: 30px 10px 10px 20px;
        color: var(--font-color);
        font-size: var(--mobile-ml);
        font-weight: 200;
        border: transparent 2px solid;
        outline: none;
        transition: all ease-in-out 0.15s;
        

        &:focus{
            background-color: var(--red10);
            border: var(--red) 2px solid;
        }
        &:focus +label{color: var(--red);}
    }
    label{
        position: absolute;
        z-index: 0;
        top: 10px;
        left: 20px;
        color: var(--inv50);
    }

    textarea{
        resize: none;
        z-index: 1;
        height: 242px;
        width: 100%;
        background-color: var(--inv10);
        border-radius: 15px;
        padding: 30px 10px 10px 20px;
        color: var(--font-color);
        font-size: var(--mobile-ml);
        font-weight: 200;
        border: transparent 2px solid;
        outline: none;
        transition: all ease-in-out 0.15s;
        backdrop-filter: blur(4px);

        &:focus{
            background-color: var(--red10);
            border: var(--red) 2px solid;
        }
        &:focus +label{color: var(--red);}
        &:focus ~ .submit-btn{background-color: var(--red);}
        &:focus ~ .submit-btn:hover{
            transform: rotate(15deg);
        
        }

    }

    .submit-btn{
        cursor: pointer;
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.5);
        background-color: var(--inv50);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 2px;
        transition: all ease-in-out 0.15s;

        &:active{transform: scale(0.96);}

    }
}


@media screen and (min-width:520px) {
    .cf-wrapper{
        .text-group-wrapper{
            .smaller-text-group{
                flex-direction: row;
            }
        }
    }
}


@media screen and (min-width:850px){
    .cf-wrapper{
        .text-group-wrapper{
            flex-direction: row;
        }
    .post-submit-message{
        p{max-width: none;}
    }
    }
}
