@import "../../App.scss";


.header{

    position: sticky;
    z-index: 1; //important car sans ça les images des cards passent par dessus je ne sais pas pourquoi
    top: 0%;
    width: 100%;
    height: fit-content;
    background-color: var(--main10);
    backdrop-filter: blur(10px);
    box-shadow:
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    0px 3px 4px 0px hsla(0,0%,0%,0.14), 
    0px 3px 3px -2px hsla(0,0%,0%,0.12), 
    0px 1px 8px 0px hsla(0,0%,0%,0.2);
    display: flex;
    justify-content: center;
    transition: background-color ease-in-out 0.15s;

    &:hover{
        background-color: var(--main);
    }

    .header-wrapper{
        height: 75px;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        max-width: 1200px;

        .to-home{
            cursor: pointer;
            width: 45px;
            height: 45px;
            border: none;
            border-radius: 50%;
            background-color: var(--inv10);
            border: 2px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
                border: 2px solid var(--inv50);
            }

            img{
                margin-right: 2px;
                transform: rotate(90deg);
                height: 20px;
            }

        }

        h1{
            background-color: var(--inv10);
            padding: 5px 10px;
            border-radius: 30px;
            font-weight: 400;
            font-size: 5vw;
        }
        p{
            text-align: center;
            font-weight: 600;
            color: var(--red);
            line-height: 0.9rem;
        }
    
    }
    
}
@media screen and (max-width:400px) {
    .header{
        .header-wrapper{

            h1{
                font-size: var(--mobile-l);
            }
        }
    }
}
@media screen and (min-width:500px) {
    .header{
        .header-wrapper{
            h1{font-size: 25px;}
        }
    }
}

@media screen and (min-width:900px) {

    
}
