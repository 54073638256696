

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
}
.stop-scrolling{
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}
body{
  height: 100%;
  width: 100%;
  font-weight: 400;
  background-color: var(--main);
  color: var(--font-color);

  --backgroundImage : url("./assets/images/toulouse-plan-background.svg");
  

  --main : #0d0d0d;
  --main10 : rgba(13, 13, 13,0.1);
  --main50:rgba(13, 13, 13,0.5);

  --inv : #F7F7F7;
  --inv10:rgba(247, 247, 247, 0.1);
  --inv50:rgba(247, 247, 247,0.5);

  --font-color : #F7F7F7;
  --font-color-inv : #0d0d0d;

  --red:#BB3636;
  --red10:rgba(187, 54, 54,0.1);
  --white:#F7F7F7;

  --mobile-l:20px;
  --mobile-ml:18px;
  --mobile-m:15px;
  --mobile-s:12px;

  --desktop-xl: 50px;
  --desktop-l: 24px;
  --desktop-m: var(--mobile-l);
  --desktop-s: var(--mobile-m);

}

.btn{
    cursor: pointer;
    text-decoration: none;
    padding: 10px 15px;
    border: none;
    color: var(--white);
    border-radius: 30px;
    font-size: var(--mobile-m);
    font-weight: 400;
    transition: all ease-in-out 0.3s;
    background-color: var(--red);
    color: var(--white);


    &:active{
        transform: scale(0.96);
    }
}

@media screen and (min-width:900px) {
    .btn{
        border: 2px solid var(--red);
        background-color: var(--red10);

        &:hover{
            background-color: var(--red);
        }
    }
}

