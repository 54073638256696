@import "../../App.scss";

.categoryCard{

    height: 210px;
    // height: 250px;
    width: 100%;
    // width: 200px;
    background-color: var(--main);
    cursor: pointer;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: transform ease-in-out 0.15s;
    border: 1px solid #1B1B1B;


    &:hover{
        transform: translateY(-5px);
    }
    &:hover .img-section::after{opacity: 100%;}
    &:active{
        transform: scale(0.99);
    }

    .img-section{
        position: relative;
        &::after{
            opacity: 100%;
            position: absolute;
            top: 8%;
            left: 5%;
            padding: 5px 10px;
            border-radius: 30px;
            content: "Bientôt disposible";
            color: var(--font)-color;
            background-color: var(--red);
            transition: opacity ease-in-out 0.15s;
        }
    }

    .desc-section{
        padding: 15px 20px ;
        h1{
            font-weight: 400;
            font-size: var(--mobile-m);
            margin-bottom: 5px;
        }
        p{
            font-weight: 200;
            font-size: var(--mobile-s);
        }
    }
}

@media screen and (min-width:484px) {
    .categoryCard{
        width: 200px;
        height: 250px;
        display: inline-block;
        margin: 10px 10px 10px 10px;
    }
}