@import "../../App.scss";

.mentions-wrapper{
    background-image: url("../../assets/images/toulouse-plan-background.svg");
    height: calc(100vh - 75px);
    min-height: 450px;
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--mobile-l);
    font-weight: 200;
    padding: 0px 20px 0px 20px;

    p{
        max-width: 600px;
        padding: 20px;
        border-radius: 15px;
        border: 1px solid var(--inv50);


    }
    span{font-weight: 400;}
}