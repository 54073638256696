@import "../../App.scss";

.devis{
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/toulouse-plan-background.svg");
    .devis-wrapper{
        max-width: 1200px;
        width: 100vw;
        margin-block: 20px 40px;

        
        .localisation{
            width: 100%;
            display: flex;
            gap: 20px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: var(--mobile-ml);
            font-weight: 200;
            padding: 20px 30px;
            gap: 60px;

    
            .map-wrapper{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .map-border{
                    padding: 10px; 
                    background-color: var(--inv10);
                    border-radius: 40px;
                }
                

                .plan-acces{
                    display: flex;
                    gap: 5px;
                    font-weight: 400;
                    color: var(--font-color);
                    text-decoration: none;
                    background-color: var(--inv10);
                    padding: 5px 10px 10px 10px;
                    border-radius: 0px 0px 16px 16px;
                    font-size: var(--mobile-m);

                    &:hover{
                        color: var(--inv50);
                    }
                }
            }
        }
    }

}


@media screen and (min-width:850px){
    .devis{
        .devis-wrapper{
            .localisation{
                align-items: flex-start;
                flex-direction: row-reverse;
                justify-content: space-between;
                font-size: var(--desktop-l);
                gap: 10px;

                p{padding-top: 20px;}
            }
        }
    }
    
}
