@import "../../App.scss";

.brandslider{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;

	.rec{
		.rec-arrow{
			color: transparent;
			background-color: transparent;
			position: relative;
			box-shadow: none;

			&:nth-child(1)::before{
				content: url("../../assets/images/arrow-down.svg");
				position: absolute;
				top: 50%;
				left: 40%;
				transform: translate(-50%,-50%) rotate(90deg);
			}
			&:nth-child(3)::before{
				content: url("../../assets/images/arrow-down.svg");
				position: absolute;
				top: 50%;
				left: 55%;
				transform: translate(-40%,-50%) rotate(-90deg);
			}

			&:focus, &:active, &:hover{color: transparent; background-color: transparent; box-shadow: none;}
			&:hover{background-color: var(--inv10);}
			&:active{transform: scale(0.95);}
		}
		.rec-dot{
			display: none;
		}

	}
}
