@import "../../App.scss";



.accueil{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    .accueil-mobile{
        width: 100%;
        .accueil-first-wrapper{
            position: relative;
            width: 100%;
            min-height: 370px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-image: url("../../assets/images/toulouse-plan-background.svg");
            &::after{
                position: absolute;
                bottom: -5%;
                content: "";
                width: 90%;
                border-top: 2px solid var(--inv50);
            }
        
            .mobile-navbar{
                width: 100%;
                padding: 20px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
        
                .hamburger-btn{
                    cursor: pointer;
                    z-index: 1;
                    
                    input{display: none;
                    
                        &:checked + div{
                            transform: rotate(360deg);
        
                        }
        
                    }
                    //animation du hamburger button
                    .menu-btn.isOpen .menu-btn--fst-part{transform: translateY(8px) rotate(45deg);}
                    .menu-btn.isOpen .menu-btn--snd-part{opacity: 0%;}
                    .menu-btn.isOpen .menu-btn--trd-part{transform: translateY(-8px) rotate(-45deg); width: 32px;}
                    div{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        transition: transform ease-in-out 0.5s;
        
                        div{
                            width: 32px;
                            height: 3px;
                            background-color: var(--red);
                            border-radius: 30px;
                        }
        
                        &:nth-child(2){
                            width: 24px;
                            transition: all 0.5s ease-in-out;
                        }
                        &:nth-child(3){
                            width: 16px;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }
        
            .learn-more{
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                font-size: var(--mobile-s);
        
                img{
                    width: 18px;
                }
            }
        }
        
        
        #navigation-menu-id{
            transition: transform ease-in-out 0.5s;
        }
        .offset{
            transform: translateX(100%);
        }
        .navigation-menu{
            position: absolute;
            top: 0%;
            left: -100%;
            height: 100%;
            width: 100%;
            background-color: var(--inv10);
            backdrop-filter: blur(10px);
            font-size: var(--mobile-l);
            font-weight: 200;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
        
            a{
                //ce code vient styliser les Link react router
                text-decoration: none;
                color: var(--font-color);
            }
            .available-soon{
                color: var(--inv50);
            }
        
            button{
                font-size: var(--mobile-l);
                font-weight: 200;
            }
        
            .navigation-menu--bottom{
                position: absolute;
                width: 100%;
                bottom: 0%;
                display: flex;
                justify-content: space-between;
                padding: 20px 15px;
        
                a{font-size:var(--mobile-m);}
        
            }
        
        
        }
        .presentation-entreprise{
            position: relative;
            margin-block: 100px 90px;
            width: 100%;
            font-size: var(--mobile-m);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            transition: transform ease-in-out 0.3s;
        
            p{
                width: 90%;
                max-width: 650px;
                font-weight: 400;
        
                span{
                    color: var(--red);
                    font-weight: 600;
                }
            }
        
        }
    }
    
    .accueil-desktop{display: none;}
}

@media (min-width:900px){

    .accueil{
        background-image: var(--backgroundImage);
        .accueil-mobile{display: none;}
    
    
        .accueil-desktop{
            width: 100%;
            max-width: 1200px;
            height: 100vh;
            min-height: 785px;
            display: unset;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            margin-bottom: 100px;
    
    
            .desktop-navbar{
                width: 100%;
                max-width: 1400px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 50px;
                padding: 50px 10% 30px 50px;
                font-size: var(--desktop-m);
                
                .link{
                    text-decoration: none;
                    color: var(--font-color);
                    padding-bottom: 2px;
                    box-shadow: inset 0px -3px 0px -1px var(--red);
                    cursor: pointer;
                    transition: box-shadow ease-in-out 0.15s;

                    &:hover{
                        box-shadow: inset 0px -12px 0px -1px var(--red);

                    }
                }
                .available-soon{
                    cursor:not-allowed;
                    color: var(--inv50);
                    box-shadow: inset 0px -3px 0px -1px var(--main);
                    &:hover{box-shadow: inset 0px -3px 0px -1px var(--main);}
                }
                .btn{
                    font-size: var(--desktop-m);
                }
    
            }
    
            .accueil-desktop--main-content{

                border: 10px solid rgba(43,43,43,0.95);
                position: relative;
                width: 90%;
                max-width: 1400px;
                height: 70%;
                max-height: 660px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                // background-color: rgb(238, 238, 238);
                background-color: var(--inv);

                
    
                .main-content{
                    position: absolute;
                    top: 0%;
                    width: 100%;
                    height: 90%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

    
                    img{
                        margin: 20px;
                        width: 25%;
                        max-width: 350px;
                        min-width: 260px;
                    }
    
                    p{
                        color: var(--font-color-inv);
                        font-size: 1rem;
                        max-width: 650px;
                        padding: 20px;
    
                        span{
                            color: var(--red);
                            font-weight: 600;
                        }
                    }
                }
    
    
                .accueil-desktop--contact{
                    position: absolute;
                    left: 50%;
                    bottom: calc(0px - 150px - 1vh);
                    transform: translate(-50%,5%);
                    width: 80%;
                }
            }
        }

    }
}


