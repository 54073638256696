@import "../../App.scss";


.produits{
    width: 100vw;
    background-image: url("../../assets/images/toulouse-plan-background.svg");
    display: flex;
    flex-direction: column;
    align-items: center;

    .produits-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 2vw;
        margin-block: 20px;

        .produits-titre-section{
            // align-self: flex-start;
            padding: 10px;
            border-radius: 16px;
            font-size: var(--mobile-l);
            font-weight: 400;
            border: 1px solid var(--inv50);
            background-color: var(--inv10);
        }

        .listes-produits{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        
            .large-figure{
                min-width: 164px;
            }
        
            figure{
                min-width: 95px;
                margin: 1vh 5px 1vh 5px;
                display: flex;
                flex-direction: column;
                gap: 10px;
        
                figcaption{
                    width: fit-content;
                    font-size: var(--mobile-m);
                    font-weight: 600;
                    border-bottom: 1px solid var(--inv);
                }
                .p-liste{
                    list-style: none;
                    font-size: var(--mobile-s);
                    font-weight: 400;
                    line-height: 1rem;
                }
            }
        
            
        }

    }
}

.categoryCardsWrapper{
    max-width: 350px;

}



@media screen and (min-width:484px) {
    .categoryCardsWrapper{
        max-width: 445px;
    }
}
@media screen and (min-width:704px) {
    .categoryCardsWrapper{
        max-width: 665px;
    }
}
@media screen and (min-width:924px) {
    .categoryCardsWrapper{
        max-width: 885px;
    }
}
@media screen and (min-width:1144px) {
    .categoryCardsWrapper{
        max-width: fit-content;
    }
}


@media screen and (min-width:440px) {
    .produits{
        .produits-wrapper{
            .listes-produits{
                .large-figure{min-width: unset;}
        
            }

        }
    }
}

@media screen and (min-width:566px) {

    .produits{
        .produits-wrapper{
            .listes-produits{
                justify-content: space-evenly;
                
            }
        }
    }
}

@media screen and (min-width:1200px) {
    .produits{
        .produits-wrapper{
            width: 1200px;

            .listes-produits{
                figure{
                    figcaption{
                        font-size: var(--mobile-l);
                    }
                    .p-liste{
                        font-size: var(--mobile-m);
                    }
                }
            }
        }
    }
}


