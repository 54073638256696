@import "../../App.scss";

.contact-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 90px;

    .contact{
        width: 90%;
        height: fit-content;
        padding: 20px;
        border-radius: 30px;
        background-color: var(--inv10);
        color: var(--font-color);
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
        overflow: scroll;

        .contact-titles{
            width: fit-content;
            display: flex;
            padding-bottom: 5px;
            list-style: none;

            .contact-btn{
                cursor: pointer;
                color: var(--inv50);
                background-color: transparent;
                border: none;
                border-bottom: 2px solid var(--inv50);
                padding: 0px 2vw 5px 2vw;
                font-size: var(--mobile-m);

            }
            .contact-btn.active{
                color: var(--font-color);
                border-bottom: 2px solid var(--inv);
            }
        }

        .contact-content{
            position: relative;
            height: 80px;
            font-size: var(--mobile-m);

            .sending-btn{
                overflow: hidden;
                cursor: pointer;
                width: 42px;
                height: 42px;
                position: absolute;
                bottom: 0%;
                right: 0%;
                border: none;
                border-radius: 30px;
                background-color: var(--red);
                transition: all ease-in-out 0.3s;
                display: flex;
                justify-content: space-evenly;
                align-items: center;


                &:active{
                    transform: scale(0.96);
                }

                .sending-btn--text{
                    color: var(--white);
                    position: unset;
                    padding: 0;
                    line-height: 0;
                    display: none;
                }

                img{width: 24px;}

            }

            p{
                position: absolute;
                padding: 10px;
                line-height: 15px;

                span{
                    font-weight: 200;
                }
            }
            .pinvisible{display: none;}
        }

    }
}

@media (min-width:(355px)) {
    .contact-wrapper{
        .contact{overflow: hidden;}
    }
}

@media (min-width:500px) {
    .contact-wrapper{
        .contact{
            .contact-content{
                .sending-btn{
                    width: 150px;

                    .sending-btn--text{
                        display: unset;
                    }

                }

            }
        }

    }
}



@media (min-width:900px){

    .contact-wrapper{
        
        .contact{
            background-color: var(--main);
            width: 100%;
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.8);

            .contact-titles{
                .contact-btn{
                    padding: 5px 4vw 5px 4vw;
                    font-size: var(--desktop-m);
                    border-bottom: none;
                    border-radius: 15px 15px 0px 0px;
                    box-shadow: inset 0 -3px 0 -1px var(--inv50);
                    transition: all .2s ease-in-out;

                    &:hover{
                        color: var(--font-color);
                        box-shadow: inset 0 -3px 0 -1px var(--inv);
                    }
                }
                .contact-btn.active{
                    transition: box-shadow .2s ease-in-out;
                    color: var(--font-color-inv);
                    box-shadow: inset 0 -40px 0 -1px var(--inv);
                    border-bottom: none;
                }
            }

            .contact-content{
                font-size: var(--desktop-m);

                .sending-btn{
                    transform: scale(1.2);
                    right: 20px;
                    border: 2px solid var(--red);
                    background-color: var(--red10);
                    color: var(--red);

                    &:hover{
                        background-color: var(--red);
                    }
                }

                .contact-horaires{
                    display: flex;
                    gap: 100px;
                    line-height: 20px;
                }
            }
        }
    }

}


@media (min-width:1200px){
    .contact-wrapper{
        .contact{
            .contact-titles{
                .contact-btn{
                    padding: 5px 48px 5px;
                }
            }
        }
    }
}


