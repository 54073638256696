@import "../../App.scss";

.footer-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--inv10);
    backdrop-filter: blur(10px);
    
    box-shadow: 0px -10px 10px 0px rgba(0,0,0,0.2);
     
    .svg-color{fill: var(--font-color);}
    .svg-stroke-color{stroke: var(--font-color);}
    .footer-top{
        position: relative;
        max-width: 1080px;
        width: 100%;
        font-size: var(--mobile-m);
        font-weight: 200;
        display: flex;
        justify-content: space-between;

        .show-on-desktop{
            display: none;
        }
        .show-on-desktop-2{
            display: none;
        }

        .footer-contact{
            padding: 20px;
            .infos{
                display: flex;
                gap: 10px;
            }
        }
        .footer-nav{
            min-width: 130px;
            padding: 20px;
            display: flex;
            align-items: center;
            font-size: var(--mobile-m);
            border-right: 1px solid var(--inv10);
            

            ul{
                display: flex;
                flex-direction: column;
                gap: 5px;
                a{
                    text-decoration: none;
                    color: var(--font-color);
                }
                li{
                    list-style: none;
                }
            }
        }
        .footer-logo{
            min-width: 172px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    .footer-sep{
        height: 0px;
        width: 100%;
        border-bottom: 1px solid var(--inv10);
    }
    .footer-bottom{
        max-width: 1080px;
        width: 100%;
        // color: var(--font-color);
        
        padding: 20px;
        display: flex; 
        justify-content: space-between;
        font-size: var(--mobile-s);
        .a{
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--font-color);
            // color: red;

        }
    }
}

@media screen and (min-width:480px) {
    .footer-wrapper{
        .footer-top{
            .show-on-desktop-2{
                display: inherit;
            }
            .footer-nav{
                ul{
                    flex-direction: row;
                }
            }
        }
    }
    
}

@media screen and (min-width:526px) {
    .footer-wrapper{
        .footer-top{
            .show-on-desktop-2{display: none;}
            .show-on-desktop{display: initial;}
            .footer-contact{
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
            }
            .footer-nav{
                ul{flex-direction: column;}
                border-left: 1px solid var(--inv10);
            }

        }
    }
}

@media screen and (min-width:794px) {
    .footer-wrapper{
        .footer-top{
            .show-on-desktop-2{display: inherit;}
            .footer-nav{
                border: none;
                ul{
                    flex-direction: row;
                    font-size: 1.9vw;
                }
            }
            .border-on-the-left{
                border-left: 1px solid var(--inv10);
            }
            .border-on-the-right{
                border-right: 1px solid var(--inv10);
            }
        }
    }    
}
@media screen and (min-width:1053px) {
    .footer-wrapper{
        .footer-top{
            .footer-nav{
                ul{font-size: var(--desktop-m);}
            }
        }
    }    
}